import { Component, OnInit, OnDestroy, ViewChild, Input } from "@angular/core";
import { MenuItem } from "primeng/api";
import { BreakpointObserver } from "@angular/cdk/layout";
import { Subscription } from "rxjs";
import { AuthService, User } from "app/services/auth.service";
import { HostDetectionService } from "app/services/host-detection.service";
import { AppUrlService } from "../../../../services/app-url.service";
import { NavigationEnd, Router } from "@angular/router";
import { BannerShowService } from "app/services/banner-show.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() analyticsQueryParams = "";
  isMobileMedia: boolean;
  isServer: boolean;
  items: MenuItem[];
  breakpointSubscription: Subscription;
  user: User = null;
  mobileMenuVisible: boolean = false;
  hostname: string = "";
  currentRoute: string = "/";

  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    public appUrlService: AppUrlService,
    hostDetectionService: HostDetectionService,
    private router: Router,
    private bannerShowService: BannerShowService
  ) {
    this.isServer = hostDetectionService.isServer;
    this.hostname = hostDetectionService.hostname;
  }

  public withBannerSpacer: boolean = false;

  ngOnInit() {
    this.bannerShowService.$isOpen.subscribe(
      (isOpen: boolean) => (this.withBannerSpacer = isOpen)
    );

    this.currentRoute = this.router.url;

    this.authService.user$.subscribe((user: User) => {
      this.user = user;
      this.createMenuItems(this.currentRoute);
    });

    this.breakpointSubscription = this.breakpointObserver
      .observe(["(max-width: 576px)"])
      .subscribe((result) => {
        this.isMobileMedia = result.matches;
        this.createMenuItems(this.currentRoute);
      });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        this.createMenuItems(this.currentRoute);
      }
    });
  }

  createMenuItems(currentRoute) {
    const tabletItems = [
      {
        label: "Бизнес решения",
        isNotLink: true,
        styleClass: "bold",
        items: [
          {
            label: "Для обслуживания",
            isNotLink: true,
            styleClass: "bold subtitle",
          },
          {
            label: "IT-аутсорсинга",
            routerLink: ["/it-autsorsing"],
          },
          {
            label: "Коммерческой недвижимости",
            routerLink: ["/upravlenie-kommercheskoj-nedvizhimostyu"],
          },
          {
            label: "Техники и оборудования",
            routerLink: ["/obsluzhivanie-oborudovaniya-tekhniki"],
          },
          {
            label: "Ресторанов и гостиниц",
            routerLink: ["/obslujivanie-restoranov-i-gostinic"],
          },
          // {
          //   label: "Розничных сетей",
          //   routerLink: ["/"],
          // },
          {
            label: "Техподдержки",
            routerLink: ["/tekhnicheskaya-podderzhka"],
          },
          {
            label: "Клининговые услуги",
            routerLink: ["/kliningovye-uslugi"],
          },
          {
            label: "Арендный бизнес",
            routerLink: ["/arenda-oborudovaniya-tekhniki-nedvizhimosti"],
          },
          // {
          //   label: "Другое",
          //   withArrow: true,
          //   routerLink: ["/"],
          // },
          {
            label: "Возможности",
            isNotLink: true,
            styleClass: "bold subtitle",
          },
          // {
          //   label: "Учет заявок клиентов",
          //   routerLink: ["/"],
          // },
          // {
          //   label: "Внутренняя техподдержка",
          //   routerLink: ["/"],
          // },
          {
            label: "Выездное обслуживание",
            routerLink: ["/vyezdnoe-obsluzhivanie"],
          },
          {
            label: "Согласование документов",
            routerLink: ["/soglasovanie-dokumentov"],
          },
          // {
          //   label: "Абонентское обслуживание",
          //   routerLink: ["/"],
          // },
          // {
          //   label: "Управление закупками",
          //   routerLink: ["/"],
          // },
          // {
          //   label: "Другое",
          //   withArrow: true,
          //   routerLink: ["/"],
          // },
        ],
      },
      {
        label: "Инструменты",
        isNotLink: true,
        styleClass: "bold",
        items: [
          {
            label: "Service Desk",
            routerLink: ["/service-desk"],
          },
          {
            label: "Help Desk",
            routerLink: ["/help-desk"],
          },
          {
            label: "Менеджер задач",
            routerLink: ["/task-menedzher"],
          },
          {
            label: "Управление проектами",
            routerLink: "https://promo.upservice.com/project-management",
          },
          {
            label: "CRM для B2B",
            routerLink: ["/crm-dlya-b2b"],
          },
          {
            label: "Омниканальный сервис",
            routerLink: "https://promo.upservice.com/omnichannel-service",
          },
          {
            label: "Чат для сайта",
            routerLink: ["/chat"],
          },
          {
            label: "Коробочное решение",
            routerLink: "https://promo.upservice.com/box",
          },
        ],
      },
      { label: "Цены", routerLink: ["/pricing"] },
      {
        label: "База знаний",
        routerLink: "https://help.upservice.com/",
        withoutQuery: true,
      },
      { label: "Контакты", routerLink: "https://promo.upservice.com/contact" },
      ...(Boolean(this.user)
        ? []
        : [
            {
              label: "Войти",
              routerLink: this.appUrlService.createUrl("/auth/login"),
              styleClass: `app-mobile-menu__auth ${
                currentRoute === "/" ? "brand" : ""
              }`,
            },
          ]),
    ];

    const mobileItems = [
      ...tabletItems,
      ...(Boolean(this.user)
        ? []
        : [
            {
              label: "Зарегистрироваться",
              routerLink: "https://app.upservice.io/forms/6ad069c3-9f00-4b6b-8fc5-7901baf2e423",
              styleClass: `app-mobile-menu__auth ${
                currentRoute === "/" ? "brand" : ""
              }`,
            },
          ]),
    ];

    const mapItems = (items) =>
      items.map((item) => ({
        ...item,
        command: (event) => {
          if (item.command instanceof Function) {
            item.command(event);
          }
        },
      }));

    if (this.isMobileMedia) {
      this.items = mapItems(mobileItems);
    } else {
      this.items = mapItems(tabletItems);
    }
  }

  ngOnDestroy() {
    this.breakpointSubscription.unsubscribe();
  }

  toggleSubmenu(index) {
    this.items[index].styleClass =
      (this.items[index].styleClass || "").indexOf("open") !== -1
        ? this.items[index].styleClass.replace(/open/gi, "")
        : `${this.items[index].styleClass} open`;
  }

  onChangeVisibleSidebar(value: boolean) {
    this.mobileMenuVisible = value;

    const body = document.getElementById("body");

    if (value) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
  }

  get logo(): string {
    return "/assets/header/logo.svg";
  }
}
